import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';
import Layout from '../components/Layout';

import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const Guides = ({
  data: {
    allGuides: { nodes },
  },
}) => {
  const sortedGuides = nodes.sort((a, b) => a.frontmatter.title.localeCompare(b.frontmatter.title));
  return (
    <Layout pageTitle="Guides">
      <Ink.Box bottom="32px">
        <Header
          title="Guides"
          subtitle="Check out these practical guides to help you understand how to design using ink and the tools provided by the ink team. "
        />
      </Ink.Box>
      <Ink.Tiles columns={[1, 2, 2, 3, 4]}>
        {sortedGuides.map(node => (
          <PreviewCard
            key={node.frontmatter.title}
            title={node.frontmatter.title}
            text={node.frontmatter.subtitle}
            actionLabel="View guide"
            actionHref={`/guides/${node.slug}`}
            previewWithCheckerboard
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default Guides;

export const query = graphql`
  query GuidesQuery {
    allGuides: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/guides/" }
        slug: { regex: "/^((?!ink-linter/|contributing/).)*$/" }
      }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
        }
        slug
      }
    }
  }
`;
